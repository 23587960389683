<template>
  <div class="policy-container">
    <!-- 主要内容 -->
    <div class="policy-content">
      <!-- 卡片列表 -->
      <div class="banner-limit flex-row flex-start policy-items">
        <div
          class="pointer flex-column card-item"
          v-for="(item, index) in items"
          :key="index"
          @click="toDet(item)"
        >
          <img class="item-img" :src="item.banner" />
          <p class="item-title">
            {{ item.name }}
          </p>
          <p class="item-time">{{ item.createTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "../../utils/util";
export default {
  components: {},
  data() {
    return {
      /* 列表信息 */
      items: [],
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      let params = {
        pageNum: 1,
        pageSize: 20,
      };
      this.$httpApi.get(`/dev-api/policyexplain/list`, params).then((res) => {
        console.log("res:", res);
        if (res.code == 200) {
          let list = res.rows;
          list.forEach((element) => {
            //  可能有多张图片 用逗号隔开
            let banners = element.barnnerimg.split(",");
            element.banner =
              this.$httpApi.baseUrl +
              "/dev-api/fileManageApi/common/fileView/" +
              banners[0];
            element.createTime = util.dateFormat(
              "YYYY-MM-DD",
              element.createTime
            );
          });
        console.log("政策解读：", list);
          this.items = list;
        }
      })
	  .catch((err) => {
	    
	  });
    },
    /* 转跳到详细的文字信息 */
    toDet(item) {
      // alert("详细信息")
      this.$router.push({
        name: "PolicyInterpretationDetail",
        params: {
          item: item,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  min-height: 650px;
}
.policy-items {
  // background: red;
  flex-flow: row wrap;
  justify-content: flex-start;
  // margin-top: 30px;
  // height: 650px;
  .card-item {
    position: relative;
    width: 270px;
    margin: 10px 20px 30px 0;
    background-color: #fff;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease; // 动画时间
    overflow: hidden; /* 当溢出的时候隐藏*/
    .item-img {
      width: 100%;
      height: 140px;
      transition: transform 0.6s ease; // 动画时间
    }
    .item-title {
      padding: 30px 10px;
      font-size: 15px;
      font-weight: bold;
      color: #0d1c28;
    }
    .item-time {
      padding: 20px 10px;
    }
    /* 动画 */
    &:hover {
      margin: 10px 20px 40px 0;
      box-shadow: 0 0 20px 1px rgba(25, 29, 241, 0.1);
      .item-img {
        transform: scale(1.1);
      }
    }
  }
}
</style>